import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemUser from '../assets/images/user.jpg';

import {
	listar, buscarImagens
} from './historicoMesActions';

class Previsao extends Component {

	state = {
		pesquisar: '',
		carrocelSelecionado: 0
	}

	constructor(props) {
		super(props);

		setInterval(() => {
			this.proximoCarrocel();
		}, 10000);
	}
	
	anteriorCarrocel() {
		
		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado - 1 == -1 ? 0 : this.state.carrocelSelecionado - 1 
		});
	}

	proximoCarrocel() {

		let { tempo, imagens } = this.props;

		let banners = (imagens.banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));

		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado + 1 == banners.length ? 0 : this.state.carrocelSelecionado + 1
		});
	}

    componentDidMount() {

		this.props.listar();
		this.props.buscarImagens();

		setInterval(() => {
			this.props.listar();
			this.props.buscarImagens();
		}, 40000);
    }

    render() {

		let { lista, imagens } = this.props;

		let banners = (imagens.banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));

		const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

		return (
			<Content>
				
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Grid cols='12 12 10 6'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: imagens.tela1 && imagens.tela2 ? 'space-between' : 'center', verticalAligment: 'center', alignItems: 'center', marginBottom: 2 }}>
							{imagens.tela1 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela1} />
							) : null}

							{imagens.tela2 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela2} />
							) : null}
						</div>
					</Grid>

					<br />

					{(lista || []).map(item => (
						<Grid  key={item.datahora} cols='12 12 10 6'>
							<div class="info-box shadow-sm">
							<div class="info-box-content" style={{ alignItems: 'center' }}>
									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', padding: 8, backgroundColor: '#c3c8e3' }}>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'left', color: '#000', fontSize: 16, fontWeight: 'bold' }}>{meses[parseInt(String(item.competencia).split('/')[1]) - 1]} de {parseInt(String(item.competencia).split('/')[0])}</div>
										</div>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'right', color: '#000', fontSize: 16, fontWeight: 'bold' }}>Chuva: {item ? parseFloat(item.chuva || 0).toFixed(1).replace('.', ',') : '-'} mm</div>
										</div>	
									</div>

									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '35%' }}>
											
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>Mínimo</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>Médio</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>Máximo</div>
										</div>
									</div>

									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '35%' }}>
											<div style={{ textAlign: 'left', color: '#000' }}>Temperatura</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.temperatura_minima || 0) : '-'}°C</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.temperatura_media || 0) : '-'}°C</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.temperatura_maxima || 0) : '-'}°C</div>
										</div>
									</div>

									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '35%' }}>
											<div style={{ textAlign: 'left', color: '#000' }}>Umidade do Ar</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.umidade_minima || 0) : '-'}%</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.umidade_media || 0) : '-'}%</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.umidade_maxima || 0) : '-'}%</div>
										</div>
									</div>

									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '35%' }}>
											<div style={{ textAlign: 'left', color: '#000' }}>Vento</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.velocidade_vento_minima || 0) : '-'} km/h</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.velocidade_vento_media || 0) : '-'} km/h</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.velocidade_vento_maxima || 0) : '-'} km/h</div>
										</div>
									</div>

									{/*<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '35%' }}>
											<div style={{ textAlign: 'left', color: '#000' }}>Orvalho</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseFloat(item.orvalho_minima || 0).toFixed(2).replace('.', ',') : '-'}°C</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseFloat(item.orvalho_media || 0).toFixed(2).replace('.', ',') : '-'}°C</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseFloat(item.orvalho_maxima || 0).toFixed(2).replace('.', ',') : '-'}°C</div>
										</div>
									</div>*/}

									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '35%' }}>
											<div style={{ textAlign: 'left', color: '#000' }}>Pressão</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.pressao_minima || 0) : '-'} hPa</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.pressao_media || 0) : '-'} hPa</div>
										</div>
										<div style={{ width: '25%' }}>
											<div style={{ textAlign: 'center', color: '#000' }}>{item ? parseInt(item.pressao_maxima || 0) : '-'} hPa</div>
										</div>
									</div>
								</div>
							</div>
						</Grid>
					))}
					
					<Grid cols='12 12 10 6'>
						<div id='carouselExampleControls' class='carousel slide' data-ride='carousel'>
							<div class='carousel-inner'>
								<ol class='carousel-indicators'>
									{banners.map((item, i) => (
										<li key={item.id} data-target='#carouselExampleIndicators' data-slide-to={i} class={i == this.state.carrocelSelecionado ? 'active' : null} ></li>
									))}
								</ol>
								{banners.map((item, i) => (
									<div key={item.id} class={`carousel-item ${i == this.state.carrocelSelecionado ? 'active' : ''}`}>
										<img class='d-block w-100' src={item.logo} alt={item.nome} />
									</div>
								))}
							</div>
						</div>
						{/*<a class='carousel-control-prev' href='#carouselExampleControls' role='button' data-slide='prev'>
							<span class='carousel-control-prev-icon' aria-hidden='true'></span>
							<span class='sr-only'>Anterior</span>
						</a>
						<a class='carousel-control-next' href='#carouselExampleControls' role='button' data-slide='next'>
							<span class='carousel-control-next-icon' aria-hidden='true'></span>
							<span class='sr-only'>Próximo</span>
						</a>*/}
					</Grid>
							
				</div>		
			</Content>
		)
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.historicoMes.aguardando,
	lista: state.historicoMes.lista,
	imagens: state.historicoMes.imagens
});
const mapDispatchToProps = dispatch => bindActionCreators({
	listar, buscarImagens
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Previsao)
