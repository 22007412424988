import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'HISTORICO_DIA_SET_AGUARDANDO',
        payload: aguardando
    };
}


export function listar() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
			dispatch(setAguardando(true));
	    	axios.get(`${consts.API_URL}/estacao/historicoDia?id_produtor=${getState().auth.usuario.id || 0}&latitude=${null}&longitude=${null}`).then(resp => {
				dispatch({
			        type: 'HISTORICO_DIA_LISTADO',
			        payload: resp
			    });
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
	}
}

export function buscarImagens() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
			dispatch(setAguardando(true));
	    	axios.get(`${consts.API_URL}/usuario/buscaImagens?id=${getState().auth.usuario.id || 0}`).then(resp => {
				dispatch({
			        type: 'HISTORICO_DIA_IMAGEM_LISTADO',
			        payload: resp
			    });
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
	}
}
