import './auth.css';
import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, signup, initForm } from './authActions';
import Row from '../common/layout/row';
import If from '../common/operator/if';
import Messages from '../common/msg/messages';
import Input from '../common/form/inputAuth';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { ThemeProvider } from '@material-ui/styles';
import imagemLogo from '../assets/images/logo.png';
import imagemLogin from '../assets/images/imagem_login.png';

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = {loginMode: true};
	}

	changeMode() {
		this.setState({ loginMode: !this.state.loginMode });
	}

	onSubmit(values) {
		const { login, signup } = this.props;
		this.state.loginMode ? login(values) : signup(values);
	}

	getStyles() {
		return makeStyles((theme) => ({
			paper: {
				marginTop: theme.spacing(8),
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			},
			avatar: {
				margin: theme.spacing(1),
				backgroundColor: theme.palette.secondary.main
			},
			form: {
				width: '100%',
				marginTop: theme.spacing(1),
			},
			submit: {
				margin: theme.spacing(3, 0, 2),
			},
		}));
	}

	getTheme() {

		return createMuiTheme({
			palette: {
				primary: {
					// light: será calculada com base em palette.primary.main,
					main: '#0288d1',
					// dark: será calculada com base em palette.primary.main,
					// contrastText: será calculada para contrastar com palette.primary.main
				},
				secondary: {
					light: '#005b9f',
					main: '#005b9f',
					// dark: será calculada com base palette.secondary.main,
					contrastText: '#005b9f',
				},
				// Usado por `getContrastText()` para maximizar o contraste entre
				// o plano de fundo e o texto.
				contrastThreshold: 3,
				// Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
				// dois índices dentro de sua paleta tonal.
				// Por exemplo, mude de Red 500 para Red 300 ou Red 700.
				tonalOffset: 0.2,
			},
		});
	}

	render() {

		const classes = this.getStyles();

		const { loginMode } = this.state;
		const { handleSubmit } = this.props;

		return (
			<ThemeProvider theme={this.getTheme()}>
				<div style={{ height: '100%' }}>
					<div style={{ display: 'flex', alignItems: 'center', height: '100vh', backgroundSize: 'cover' }}>
						<Container component="main" maxWidth="xs" style={{ width: '100%' }}>
							<CssBaseline />
							{/*<h1 style={{ color: '#fff', textAlign: 'center', paddingBottom: 20 }}>S I G</h1>*/}
							<div className={classes.paper} style={{ backgroundColor: '#fff', paddingLeft: 16, paddingTop: 40, paddingRight: 16, paddingBottom: 40, borderRadius: 10, textAlign: 'center' }}>

								{/* <img src={imagemLogo} style={{ width: 300, marginBottom: 10 }}/> */}
								<h3 style={{ marginTop: 20 }}>Grifo</h3>

								{loginMode ? (
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.onSubmit(v))}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="email"
											label="E-mail"
											name="email"
											type="mail"
											autoComplete="email"
											autoFocus
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													email: e.target.value
												});
											})} />

										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="senha"
											label="Senha"
											type="password"
											id="senha"
											autoComplete="current-password"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: e.target.value
												});
											})} />

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit} 
											style={{ marginTop: 20 }}>
											Entrar
										</Button>

										<div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 16 }}>
											<a href='#' style={{ color: '#005b9f', marginLeft: 8 }} onClick={() => this.changeMode()}>Crie aqui</a>
											<p>Ainda não tem conta?</p>
										</div>
									</form>
								) : (
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.onSubmit(v))}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="nome"
											label="Nome"
											name="nome"
											autoFocus
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													nome: e.target.value
												});
											})} />

										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="email"
											label="E-mail"
											name="email"
											type="mail"
											autoComplete="email"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													email: e.target.value
												});
											})} />
											
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="telefone"
											label="telefone"
											name="telefone"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													telefone: e.target.value
												});
											})} />
											
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="codigo_acesso"
											label="Código de Acesso"
											name="codigo_acesso"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													codigo_acesso: e.target.value
												});
											})} />

										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="senha"
											label="Senha"
											type="password"
											id="senha"
											autoComplete="current-password"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: e.target.value
												});
											})} />
											
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="senha_confirmar"
											label="Confirmar Senha"
											type="password"
											id="senha_confirmar"
											autoComplete="current-password"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha_confirmar: e.target.value
												});
											})} />

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit} 
											style={{ marginTop: 20 }}>
											Criar Conta
										</Button>

										<div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 16 }}>
											<a href='#' style={{ color: '#005b9f', marginLeft: 8 }} onClick={() => this.changeMode()}>Voltar aqui</a>
											<p>Deseja Voltar para o login?</p>
										</div>
									</form>
								)}
							</div>
							<Snackbar
								open={this.props.message.open}>
								<MuiAlert severity={this.props.message.type} variant="filled">
			    					{this.props.message.text}
			  					</MuiAlert>
						 	</Snackbar>
						</Container>
					</div>
				</div>
			</ThemeProvider>
		);
	}
}

Auth = reduxForm({form: 'authForm'})(Auth);
const mapStateToProps = state => ({
	message: state.auth.message,
	formularioValues: getFormValues('authForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ login, signup, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
