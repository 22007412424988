import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemUser from '../assets/images/user.jpg';

import {
	buscarPrevisao, buscarImagens
} from './previsaoActions';

class Previsao extends Component {

	state = {
		pesquisar: '',
		carrocelSelecionado: 0
	}

	constructor(props) {
		super(props);

		setInterval(() => {
			this.proximoCarrocel();
		}, 10000);
	}
	
	anteriorCarrocel() {
		
		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado - 1 == -1 ? 0 : this.state.carrocelSelecionado - 1 
		});
	}

	proximoCarrocel() {

		let { tempo, imagens } = this.props;

		let banners = (imagens.banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));

		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado + 1 == banners.length ? 0 : this.state.carrocelSelecionado + 1
		});
	}

    componentDidMount() {

		this.props.buscarPrevisao();
		this.props.buscarImagens();

		setInterval(() => {
			this.props.buscarPrevisao();
			this.props.buscarImagens();
		}, 40000);
    }

    render() {

		let { lista, imagens } = this.props;

		let banners = (imagens.banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));
		
		let previsaoAgora = this.props.lista[0];
		let diasSemana = [
			{ id: 0, nome: 'Domingo', nomeCompleto: 'Domingo', abreviacao: 'Dom' },
			{ id: 1, nome: 'Segunda', nomeCompleto: 'Segunda-feira', abreviacao: 'Seg' },
			{ id: 2, nome: 'Terça', nomeCompleto: 'Terça-feira', abreviacao: 'Ter' },
			{ id: 3, nome: 'Quarta', nomeCompleto: 'Quarta-feira', abreviacao: 'Qua' },
			{ id: 4, nome: 'Quinta', nomeCompleto: 'Quinta-feira', abreviacao: 'Qui' },
			{ id: 5, nome: 'Sexta', nomeCompleto: 'Sexta-feira', abreviacao: 'Sex' },
			{ id: 6, nome: 'Sábado', nomeCompleto: 'Sábado', abreviacao: 'Sáb' }
		];

		return (
			<Content>
				
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Grid cols='12 12 10 6'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: imagens.tela1 && imagens.tela2 ? 'space-between' : 'center', verticalAligment: 'center', alignItems: 'center', marginBottom: 2 }}>
							{imagens.tela1 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela1} />
							) : null}

							{imagens.tela2 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela2} />
							) : null}
						</div>
					</Grid>

					<Grid cols='12 12 10 6'>
						<div class="info-box shadow-sm">
							<div class="info-box-content" style={{ alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
								<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
									<div style={{ width: '50%', paddingRight: 10 }}>
										<div style={{ textAlign: 'right', color: '#000', fontSize: 14 }}>{diasSemana.filter(dia => dia.id == new Date().getDay())[0].nomeCompleto}, {DateFormat.getDataHoraAtual().split(' ')[1]}</div>
										<div style={{ textAlign: 'right', color: '#000', fontSize: 40, paddingTop: 30, fontWeight: 'bold' }}>{previsaoAgora ? parseInt(previsaoAgora.temperatura || 0) : '-'}°C</div>
									</div>
									<div style={{ width: '50%', paddingLeft: 10 }}>
										<div style={{ textAlign: 'left', color: '#000' }}>Chuva: {previsaoAgora ? parseFloat(previsaoAgora.chuva || 0).toFixed(1).replace('.', ',') : '-'} mm</div>
										<div style={{ textAlign: 'left', color: '#000', paddingTop: 10 }}>Umidade: {previsaoAgora ? parseInt(previsaoAgora.umidade || 0) : '-'} %</div>
										<div style={{ textAlign: 'left', color: '#000', paddingTop: 10 }}>Vento: {previsaoAgora ? parseInt(previsaoAgora.velocidade_vento || 0) : '-'} km/h</div>
									</div>
								</div>
							</div>
						</div>
					</Grid>

					{lista.filter((item, i) => i > 0).map(item => (
						<Grid  key={item.datahora} cols='12 12 10 6'>
							<div class="info-box shadow-sm">
								<div class="info-box-content" style={{ alignItems: 'center' }}>
									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'left', color: '#000', fontSize: 14 }}>{diasSemana.filter(dia => dia.id == new Date(item.datahora).getDay())[0].nomeCompleto}</div>
										</div>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'right', color: '#000', fontSize: 14, fontWeight: 'bold' }}>{item ? parseInt(item.temperatura_minima || 0) : '-'}°C | {item ? parseInt(item.temperatura_maxima || 0) : '-'}°C</div>
										</div>	
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'left', color: '#000' }}>Chuva: {item ? parseFloat(item.chuva || 0).toFixed(1).replace('.', ',') : '-'} mm</div>
										</div>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'right', color: '#000' }}>Umidade: {item ? parseInt(item.media_umidade || 0) : '-'} %</div>
										</div>	
									</div>
								</div>
							</div>
						</Grid>
					))}
					
					<Grid cols='12 12 10 6'>
						<div id='carouselExampleControls' class='carousel slide' data-ride='carousel'>
							<div class='carousel-inner'>
								<ol class='carousel-indicators'>
									{banners.map((item, i) => (
										<li key={item.id} data-target='#carouselExampleIndicators' data-slide-to={i} class={i == this.state.carrocelSelecionado ? 'active' : null} ></li>
									))}
								</ol>
								{banners.map((item, i) => (
									<div key={item.id} class={`carousel-item ${i == this.state.carrocelSelecionado ? 'active' : ''}`}>
										<img class='d-block w-100' src={item.logo} alt={item.nome} />
									</div>
								))}
							</div>
						</div>
						{/*<a class='carousel-control-prev' href='#carouselExampleControls' role='button' data-slide='prev'>
							<span class='carousel-control-prev-icon' aria-hidden='true'></span>
							<span class='sr-only'>Anterior</span>
						</a>
						<a class='carousel-control-next' href='#carouselExampleControls' role='button' data-slide='next'>
							<span class='carousel-control-next-icon' aria-hidden='true'></span>
							<span class='sr-only'>Próximo</span>
						</a>*/}
					</Grid>
							
				</div>		
			</Content>
		)
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.previsao.aguardando,
	lista: state.previsao.lista,
	imagens: state.previsao.imagens
});
const mapDispatchToProps = dispatch => bindActionCreators({
	buscarPrevisao, buscarImagens
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Previsao)
